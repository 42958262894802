import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';

const tenantId = 'lascananl';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'lascana.nl' },
    clientId: 'LascanaNl',
    i18n: {
      defaultLocale: 'nl-NL',
      locales: ['nl-NL'],
      languages: ['nl'],
    },
    tenantId,
    company: {
      name: 'Lascana',
      seoTitleName: 'LASCANA',
    },
    headerSlots: {
      payback: false,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    search: {
      isInspiringSearchEnabled: false,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
      cmsFontToTheme: {
        '.font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
        '.font-normal-300': {
          fontWeight: 'fontWeightLight',
        },
      },
    },
    product: {
      cliplister: {
        customerId: '81856',
      },
      flixmedia: {
        distributorId: 18441,
        enabled: true,
      },
      hasKIM: false,
      recommendations: {
        dv: {
          slots: {
            1: 'searchProductToProducts',
            3: null,
            4: 'searchReplace',
          },
        },
        atb: {
          slots: {
            1: 'searchBasketToProducts',
            2: null,
          },
        },
      },
    },
    recommendations: {
      provider: 'empiriecom',
    },
    installmentCalculator: {
      hasLegalHints: false,
      hasLegalHintsImage: true,
      InstallmentCalculatorCallToActionLayout: null,
      hasSpecialCreditColors: true,
    },
    order: {
      premium: { enabled: false },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'nl' },
          { params: { type: ['socket'] }, locale: 'nl' },
        ],
      },
    },
    forms: {
      apiAgnitasUrl: {
        values: {
          sendAdditionalSubscribe: true,
        },
      },
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.changed.email', value: 'emailChanged' },
          { label: 'forms.type.subject.option.changed.data', value: 'dataChanged' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.retoure', value: 'retoure' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          { label: 'forms.type.subject.option.delivery', value: 'delivery' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.complaint.comment', value: 'complaint' },
          { label: 'forms.type.subject.option.return.data', value: 'return' },
          { label: 'forms.type.subject.option.coupons', value: 'coupons' },
          { label: 'forms.type.subject.option.newsletter', value: 'newsletter' },
          { label: 'forms.type.subject.option.other', value: 'other' },
        ],
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|199', 'unsubscribe|299', 'unsubscribe|99'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|199',
            options: [
              {
                id: 'unsubscribe-otto-lascana',
                value: 'unsubscribe|199',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe.otto-lascana',
              },
              {
                id: 'unsubscribe-lascana',
                value: 'unsubscribe|299',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe.lascana',
              },
              {
                id: 'unsubscribe-otto',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe.otto',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              type: 'pause',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              defaultValue: '7',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink: 'https://www.ottoversand.at/service-hilfe/newsletter/abmelden/',
          voucherLink: 'https://www.ottoversand.at/service-hilfe/gutscheine-rabatte/',
        },
      },
      validation: {
        rules: {
          fileUpload: {
            acceptedFormats: 'image/*,.heic,.heif,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.pdf,.txt',
            regexAcceptedUploadFormats:
              /\.(jpe?g|png|gif|webp|bmp|heic|heif|docx?|xlsx?|pptx?|rtf|pdf|txt)$/i,
          },
        },
      },
    },
    customerRatings: {
      ratingButtonEnabled: false,
      forceEnableRatingButtonParam: 'openWriteReview',
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: { appUrl: 'https://app.adjust.com/b8763lc_t4tzjhg' },
    insiderPushNotifications: {
      enabled: true,
      apiKey: '532e1d55740d5727a5bcb2635a0b67f1',
      partnerName: 'lascananl',
      partnerId: 10005260,
    },
    tracking: {
      soastaId: '',
      tagmanagerId: 'GTM-M5VHDJHK',
      googleSiteVerificationID: [
        '7moR17MZ9oChnojTPQSSshkARGweKTDrW1QI9GfTeZk',
        'SmfKtbV24V7UjCSkUXnXjYzsXlu28AMMvQnugEc-5Ww',
      ],
      msvalidateID: '30C653343D71C28456707D55556ED4BC',
      pinterestRichPinVerificationID: '67d9bf4ad3f9c8c5b41a2f8d2eba4152',
      schemaOrgOrganisation: {
        'de-AT': {
          addressCountry: 'AT',
          addressRegion: 'ST',
          addressLocality: 'Graz',
          alternateName: ['OTTO', 'Otto Versand', 'Ottoversand'],
          areaServed: 'AT',
          description:
            'Wir bei OTTO möchten das digitale Einkaufen zum Erlebnis machen. Unser Ziel ist es unsere KundInnen zu inspirieren und auf alle Wünsche passende Angebote zu liefern.',
          contactEmail: 'kundenservice@ottoversand.at',
          contactUrl: 'https://www.ottoversand.at/service-hilfe/kontakt',
          email: 'webmaster@ottoversand.at',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Otto GmbH',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Otto',
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/ottoversand',
            'https://www.instagram.com/ottoversand/',
            'https://www.pinterest.at/ottoversand/',
            'https://www.youtube.com/user/OTTOAustria',
            'https://www.online-shop.at/ottoversand',
          ],
          telephone: '+43-316-606-888',
          url: 'https://www.ottoversand.at',
          postalCode: '8020',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.ottoversand.at/s/',
          },
          streetAddress: 'Alte Poststraße 152',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: '',
      },
    },
    headerInformation: {
      nl: {
        description: `Een ruime collectie in badmode en lingerie koop je online bij LASCANA. Van bikini's en badpakken tot bh's en nachtmode. 14 dagen bedenktijd en gratis ruilen.`,
        title: 'LASCANA | Badmode, lingerie & nachtmode kopen bij LASCANA',
        canonical: 'https://www.lascana.nl',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      enabled: false,
      tenantId: '1000',
    },
    dynamicYieldTracking: {
      key: '',
    },
    geoCountryLayer: {
      enabled: false,
      flag: 'nl',
      alternatives: [
        {
          flag: 'de',
          shops: [{ link: 'https://www.lascana.de' }],
        },
        {
          flag: 'at',
          shops: [{ link: 'https://www.lascana.at' }],
        },
        {
          flag: 'ch',
          shops: [{ link: 'https://www.lascana.ch' }],
        },
        {
          flag: 'it',
          shops: [{ link: 'https://www.bonprix.it' }],
        },
      ],
    },
    priceFormat: 'Iso',
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
